import { ApolloError } from '@apollo/client/errors'
import { GetUserLocationDocument } from '@kijiji/generated/graphql-types'
import { type GetServerSideProps, type Redirect } from 'next'
import { type Session } from 'next-auth'
import { v4 as generateUUID } from 'uuid'

import { getCMSContentModule } from '@/components/homepage/hero-cms-banner/getCMSContentModule'
import { HomepageInner } from '@/components/homepage/homepage-inner/HomepageInner'
import { getMenuPrefetch } from '@/components/homepage/mega-menu/getMenuPrefetch'
import { TRANSLATION_KEYS } from '@/constants/localization'
import { debugServerTime } from '@/domain/debugServerTiming'
import { initializePageServer } from '@/domain/initializePageServer'
import { isValidLocation } from '@/domain/location/isValidLocation'
import { addApolloState } from '@/lib/apollo/apolloClient'
import profile from '@/lib/metrics/profileWrapper'

type HomeProps = {
  userAgent: string
  correlationId: string
  session: Session | null
}

/**
 * The homepage could be called by accessing `/`
 * or paths like: `/h-<location-seo-name>/<locationId>
 *
 * When there are parameters on the homepage path, then the user's location
 * should be switched to that defined location
 */
const Home = ({ correlationId, userAgent }: HomeProps) => {
  return <HomepageInner correlationId={correlationId} userAgent={userAgent} />
}

export const getServerSideProps: GetServerSideProps = profile<GetServerSideProps>(
  'homepage',
  'getServerSideProps',
  async (context) => {
    const gSSPstart = Date.now()
    const { req, res } = context

    const {
      rawProps,
      apolloClient,
      locale: { cookieLocale },
    } = await initializePageServer('homepage', context, [TRANSLATION_KEYS.HOME])

    const correlationId = generateUUID()
    const userAgent = req ? req.headers['user-agent'] ?? '' : navigator.userAgent

    /**
     * Queries we want to pre-fetch on the server to load asap in the page
     * */
    const location = apolloClient.readQuery({ query: GetUserLocationDocument })
    const locationId = location?.userLocation.id

    // Note: isValidLocation doesn't actually check if location is valid, just that the id is a number
    if (!isValidLocation(locationId)) {
      return { redirect: { destination: '/h-canada/0', permanent: false } }
    }

    const queryResults = await Promise.allSettled([
      profile(
        'homepage',
        'getMenuPrefetch',
        getMenuPrefetch
      )({ apolloClient, cookieLocale, locationId }),
      profile('homepage', 'getCMSContentModule', getCMSContentModule)({ apolloClient, locationId }),
    ])

    let locationErrorRedirect: { redirect: Redirect } | null = null

    queryResults.forEach((result) => {
      if (
        result.status !== 'rejected' ||
        !(result.reason instanceof ApolloError && result.reason.graphQLErrors.length > 0)
      ) {
        return
      }

      const errorCode = result.reason.graphQLErrors[0].extensions?.code
      const errorMessage = result.reason.graphQLErrors[0].message

      if (errorCode === 'LOCATION_ERROR' || errorMessage.includes('location')) {
        locationErrorRedirect = { redirect: { destination: '/h-canada/0', permanent: false } }
      }
    })

    if (locationErrorRedirect) {
      return locationErrorRedirect
    }

    const pageProps: HomeProps = {
      correlationId,
      userAgent,
      ...rawProps,
    }

    const props = profile('homepage', 'addApolloState', addApolloState)(apolloClient, pageProps)
    debugServerTime('props', res, gSSPstart)

    return { props }
  }
)

export default Home
